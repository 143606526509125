<template>
  <v-dialog v-model="show" width="600">
    <v-card>
      <v-card-title>Подтвердите действие</v-card-title>

      <v-divider/>

      <v-card-text class="pt-4 body-1">
        Подтвердите, что хотите {{ itemState }} признак “Контролировать выполнение по расписанию“
        для маршрута № {{ selected.vcroutesRoutecode }}
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn
          @click="show = false"
          color="red"
          outlined
        >
          Отмена
        </v-btn>

        <v-spacer/>

        <v-btn
          @click="execute"
          :loading="loading"
          color="primary"
          dark
        >
          Подтвердить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'SheduleControl',
    props: {
      collection: {
        type: Object,
        required: true,
      }
    },
    data: () => ({
      show: true,
      selected: null,
      loading: false,
      currentMode: null,
    }),
    created() {
      this.selected = this.collection.master.selected;
      if (!this.selected){
        jet.msg({
          text:'Необходимо выбрать запись!',
          color:'warning'
        });
        return;
      }

      this.currentMode = this.selected.vcroutesControlschedule;
    },
    computed: {
      itemState() {
        return !this.currentMode ? 'установить' : 'снять';
      },
    },
    methods: {
      async execute() {
        this.loading = true;
        let mode = '';
        try {
          const result = await this.changeMode(this.selected.vcroutesId, !this.currentMode);
          mode = !this.currentMode ? 'Установка' : 'Снятие'
          this.show = false;
          if (result) {
            jet.msg({
              text: `${mode} признака "Контролировать 
                выполнение по расписанию" для маршрута № ${this.selected.vcroutesRoutecode} 
                ${this.selected.vcroutesRoutename} выполнено успешно!`
            });
            this.collection.refresh(this.selected.vcroutesId);
          }
        } catch (ex) {
          jet.msg({
            text: 'Невозможно изменить параметр "Контролировать'
              + 'выполнение по расписанию".Попробуйте снова или'
              + 'обратитесь в службу поддержки',
            color: 'warning'
          });
          return;
        };
        this.loading = false;
      },
      async changeMode(routeId, mode) {
        const changeMode = {
          type: 'core-update',
          query: 'sin2:/v:acd2f542-598c-4540-b46c-03375098d467',
          params: [
            { id: 'ID', type: 'id', value: routeId },
            { id: 'controlSchedule', type: 'boolean', value: mode },
          ]
        };
        const response = await jet.http.post(changeMode);
        if (response.error) {
          throw response.error;
        }
        return true;
      }
    }
  };
</script>
